import React, { useEffect, useState } from "react";
import { dateTimeFormat } from "../../common/commonFucntion";
import Sidebar from "../../Components/Sidebar";
import axios from "../../axiosConfig";
import { useNavigate, useParams } from "react-router";

const AddUpdateSubCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [allCategoryList, setAllCategoryList] = useState([]);
  const [subCategoryNameGJ, setSubCategoryNameGJ] = useState("");
  const [subCategoryNameHI, setSubCategoryNameHI] = useState("");
  const [subCategoryNameEN, setSubCategoryNameEN] = useState("");
  const [categoryId, setCategoryId] = useState(0);
  const [error, setError] = useState("");
  const [button,setButton] = useState(false)
  const [image,setImage] = useState("")
  const [base64Image,setBase64Image] = useState("")

  useEffect(() => {
    if(id){
      axios
        .post("/admin/getSubCategoryById", {
          id: id,
        })
        .then((response) => {
          if (response.data && response.data.data.length > 0) {
            setCategoryId(response.data.data[0].categoryId);
            setSubCategoryNameGJ(response.data.data[0].subCategoryNameGJ);
            setSubCategoryNameHI(response.data.data[0].subCategoryNameHI);
            setSubCategoryNameEN(response.data.data[0].subCategoryNameEN);
          }
        })
        .catch((e) => {});
    }
    getCategory();
  }, []);

  const getCategory = () => {
    axios
      .post("/admin/allCategoryList")
      .then((response) => {
        if (response.data && response.data.data.length > 0) {
          setAllCategoryList(response.data.data);
          setCategoryId(response.data.data[0].id)
        }
      })
      .catch((e) => {});
  };

  const updateSubCategory = () => {
    setButton(true)

    let formData = new FormData();
    formData.append("id", id);
    formData.append("categoryId", categoryId);
    formData.append("subCategoryNameGJ", subCategoryNameGJ);
    formData.append("subCategoryNameHI", subCategoryNameHI);
    formData.append("subCategoryNameEN", subCategoryNameEN);
    if(image){
      formData.append("image", image);
    }

    let subCategoryUpdateConfig = {
      method: "post",
      url: "/admin/updateSubCategory",
      data: formData,
    };

    axios(subCategoryUpdateConfig)
      .then((response) => {
        setButton(false)
        navigate("/subcategory");
      })
      .catch((e) => {
        setButton(false)
      });
  };

  const addSubCategory = () => {
    setButton(true)
    if (!subCategoryNameGJ || !subCategoryNameHI || !subCategoryNameEN || !image) {
      setError("All field require!");
      errorTimeOut();
      setButton(false)
      return;
    }
    let formData = new FormData();
    formData.append("categoryId", categoryId);
    formData.append("subCategoryNameGJ", subCategoryNameGJ);
    formData.append("subCategoryNameHI", subCategoryNameHI);
    formData.append("subCategoryNameEN", subCategoryNameEN);
    formData.append("image", image);

    let subCategoryConfig = {
      method: "post",
      url: "/admin/addSubCategory",
      data: formData,
    };
    axios(subCategoryConfig)
      .then((response) => {
        setButton(false)
        navigate("/subcategory");
      })
      .catch((e) => {
        setButton(false)
      });
  };

  const errorTimeOut = () => {
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  const imageValidation = (file) => {
    if (!file) return;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      setBase64Image(e.target.result);
    };
    const allowFileType = ["jpeg", "jpg", "png"];
    const getFileType = file.type.split("/").pop();
    if (allowFileType.includes(getFileType)) {
      setImage(file);
    } else {
      setImage("");
      setError("Slider image type not valid!");
      errorTimeOut();
    }
  };

  return (
    <>
      <Sidebar />
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Add Sub Category</h5>

                  <form>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Category Name
                      </label>
                      <div class="col-sm-4">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={categoryId}
                          onChange={(e) => {
                            setCategoryId(e.target.value)
                          }}
                        >
                          {allCategoryList.map((data,i) => (
                            <option value={data.id} key={i}>
                              {data.categoryNameGJ}, {data.categoryNameHI},{" "}
                              {data.categoryNameEN},{" "}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Sub Category Name gujarati
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Category Name"
                          value={subCategoryNameGJ}
                          onChange={(e) => setSubCategoryNameGJ(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Sub Category Name hindi
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Category Name"
                          value={subCategoryNameHI}
                          onChange={(e) => setSubCategoryNameHI(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Sub Category Name english
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Category Name"
                          value={subCategoryNameEN}
                          onChange={(e) => setSubCategoryNameEN(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Image
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Sub Category Name"
                          onChange={(e) => imageValidation(e.target.files[0])}
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label
                        for="inputText"
                        class="col-sm-3 col-form-label"
                      ></label>
                      <div class="col-sm-4">
                        <div style={{ padding: "10px" }}>
                          {base64Image ? (
                            <img
                              src={base64Image}
                              height="60"
                              width="60"
                              rounded="true"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    {id ? (
                      <div class="row mb-3">
                        <label class="col-sm-3 col-form-label"></label>
                        <div class="col-sm-9">
                          {error && <div style={{ color: "red" }}>{error}</div>}
                          <button
                            type="button"
                            class="btn btn-primary"
                            disabled={button}
                            onClick={() => updateSubCategory()}
                          >
                            Update Sub Category
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div class="row mb-3">
                        <label class="col-sm-3 col-form-label"></label>
                        <div class="col-sm-9">
                          {error && <div style={{ color: "red" }}>{error}</div>}
                          <button
                            type="button"
                            class="btn btn-primary"
                            disabled={button}
                            onClick={() => addSubCategory()}
                          >
                            Add Sub Category
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AddUpdateSubCategory;
