import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import axios from "./axiosConfig";

import Login from "./Page/Login";
import Dashboard from "./Page/Dashboard";
import Category from "./Page/category/Category";
import AddCategory from "./Page/category/addUpdateCategory";
import SubCategory from "./Page/subcategory/SubCategory";
import AddSubCategory from "./Page/subcategory/addUpdateSubCategory";
import UpdateSubCategory from "./Page/subcategory/addUpdateSubCategory";
import Slider from "./Page/slider/Slider";
import AddSlider from "./Page/slider/addUpdateSlider";
import File from "./Page/file/File";
import AddFile from "./Page/file/addUpdateFile";
import UpdateFile from "./Page/file/addUpdateFile";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const getToken = localStorage.getItem("token");
    if (getToken) {
      axios
        .post("/admin/adminVerify", { token: getToken })
        .then((res) => {
          if (res && res.data) {
            const getStatus = res.data;
            if (getStatus.status == 2) {
              console.log("No Admin");
              navigate("/")
            }
          }
        })
        .catch((e) => {});
    }else{
      navigate("/")
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/category" element={<Category />} />
      <Route path="/addcategory" element={<AddCategory />} />
      <Route path="/updatecategory/:id" element={<AddCategory />} />
      <Route path="/subcategory" element={<SubCategory />} />
      <Route path="/addsubcategory" element={<AddSubCategory />} />
      <Route path="/updatesubcategory/:id" element={<UpdateSubCategory />} />
      <Route path="/slider" element={<Slider />} />
      <Route path="/addslider" element={<AddSlider />} />
      <Route path="/file" element={<File />} />
      <Route path="/addfile" element={<AddFile />} />
      <Route path="/updatefile/:id" element={<UpdateFile />} />
    </Routes>
  );
}

export default App;
