import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import axios from "../axiosConfig";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState("");

  useEffect(() => {
    axios
      .post("/admin/dashboard")
      .then((response) => {
        if (response && response.data) {
          setDashboardData(response.data);
        }
      })
      .catch((e) => {});
  }, []);

  return (
    <>
      <Sidebar />

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Dashboard</h1>
        </div>

        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-xxl-3 col-md-6">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">Category</h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i class="bi bi-bookmark-fill"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{dashboardData && dashboardData.categoryCount}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-3 col-md-6">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      <h5 className="card-title">Sub Category</h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-bookmarks-fill"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{dashboardData && dashboardData.subCategoryCount}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-md-6">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title">File</h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i class="bi bi-file-earmark-music-fill"></i>
                        </div>
                        <div className="ps-3">
                          <h6>{dashboardData && dashboardData.fileCount}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-md-6">
                  <div className="card info-card revenue-card">
                    <div className="card-body">
                      <h5 className="card-title">Slider</h5>

                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="bi bi-image-fill"></i>
                        </div>
                        <div className="ps-3">
                          <h6>
                            {dashboardData && dashboardData.sliderCount}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Dashboard;
