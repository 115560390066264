import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import axios from "../../axiosConfig";
import { useNavigate, useParams } from "react-router";

const AddUpdateSubCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [sliderImage, setSliderImage] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const [error, setError] = useState("");
  const [button,setButton] = useState(false)

  const sliderImageValidation = (file) => {
    if (!file) return;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      setBase64Image(e.target.result);
    };
    const allowFileType = ["jpeg", "jpg", "png"];
    const getFileType = file.type.split("/").pop();
    if (allowFileType.includes(getFileType)) {
      setSliderImage(file);
    } else {
      setSliderImage("");
      setError("Slider image type not valid!");
      errorTimeOut();
    }
  };

  const addSubCategory = () => {
    setButton(true)
    if (!sliderImage) {
      setError("Slider image field require!");
      errorTimeOut();
      setButton(false)
      return;
    }
    let formData = new FormData();
    formData.append("files", sliderImage);

    let sliderConfig = {
      method: "post",
      url: "/admin/addSlider",
      data: formData,
    };
    axios(sliderConfig)
      .then((response) => {
        setButton(false)
        navigate("/slider");
      })
      .catch((e) => {
        setButton(false)
      });
  };

  const errorTimeOut = () => {
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <>
      <Sidebar />
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Add Slider</h5>

                  <form>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Slider Image
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Category Name"
                          // value={sliderImage}
                          onChange={(e) =>
                            sliderImageValidation(e.target.files[0])
                          }
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label
                        for="inputText"
                        class="col-sm-3 col-form-label"
                      ></label>
                      <div class="col-sm-4">
                        <div style={{ padding: "10px" }}>
                          {base64Image ? (
                            <img
                              src={base64Image}
                              height="60"
                              width="60"
                              rounded="true"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    {id ? (
                      <div class="row mb-3">
                        <label class="col-sm-3 col-form-label"></label>
                        <div class="col-sm-9">
                          {error && <div style={{ color: "red" }}>{error}</div>}
                          <button
                            type="button"
                            class="btn btn-primary"
                            disabled={button}
                            // onClick={() => updateSubCategory()}
                          >
                            Update Slider
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div class="row mb-3">
                        <label class="col-sm-3 col-form-label"></label>
                        <div class="col-sm-9">
                          {error && <div style={{ color: "red" }}>{error}</div>}
                          <button
                            type="button"
                            class="btn btn-primary"
                            disabled={button}
                            onClick={() => addSubCategory()}
                          >
                            Add Slider
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AddUpdateSubCategory;
