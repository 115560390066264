import React, { useEffect, useState } from "react";
import { dateTimeFormat } from "../../common/commonFucntion";
import Sidebar from "../../Components/Sidebar";
import axios from "../../axiosConfig";
import { useNavigate, useParams } from "react-router";
import { url } from "../../serverConfig";

const AddUpdateCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [allCategoryList, setAllCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [allSubCategoryList, setAllSubCategoryList] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState(0);
  const [file, setFile] = useState("");
  const [image, setImage] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const [filePath, setFilePath] = useState("");
  const [folderName, setFolderName] = useState("");
  const [nameGJ, setNameGJ] = useState("");
  const [nameHI, setNameHI] = useState("");
  const [nameEN, setNameEN] = useState("");
  const [authorGJ, setAuthorGJ] = useState("");
  const [authorHI, setAuthorHI] = useState("");
  const [authorEN, setAuthorEN] = useState("");
  const [error, setError] = useState("");
  const [button, setButton] = useState(false);

  useEffect(() => {
    if (id) {
      axios
        .post("/admin/getFileById", {
          id: id,
        })
        .then((response) => {
          if (response.data && response.data.data.length > 0) {
            let fileData = response.data.data[0];
            setCategoryId(fileData.categoryId);
            setSubCategoryId(fileData.subCategoryId);
            setFolderName(`${fileData.folderName}`.toLowerCase());
            setNameGJ(fileData.nameGJ);
            setNameHI(fileData.nameHI);
            setNameEN(fileData.nameEN);
            setAuthorGJ(fileData.authorGJ);
            setAuthorHI(fileData.authorHI);
            setAuthorEN(fileData.authorEN);
            setFilePath(fileData.filePath);
          }
        })
        .catch((e) => {});
    }
    getCategory();
    getSubCategory();
  }, []);

  const getCategory = () => {
    axios
      .post("/admin/allCategoryList")
      .then((response) => {
        if (response.data && response.data.data.length > 0) {
          setAllCategoryList(response.data.data);
        }
      })
      .catch((e) => {});
  };

  const getSubCategory = () => {
    axios
      .post("/admin/allSubCategoryList")
      .then((response) => {
        if (response.data && response.data.data.length > 0) {
          setAllSubCategoryList(response.data.data);
        }
      })
      .catch((e) => {});
  };

  const updateFile = () => {
    setButton(true);
    let fileUpdateConfig = {
      method: "post",
      url: "/admin/updateFile",
      data: {
        id: id,
        categoryId: categoryId,
        subCategoryId: subCategoryId,
        nameGJ: nameGJ,
        nameHI: nameHI,
        nameEN: nameEN,
        authorGJ: authorGJ,
        authorHI: authorHI,
        authorEN: authorEN,
      },
    };

    axios(fileUpdateConfig)
      .then((response) => {
        setButton(false);
        navigate("/file");
      })
      .catch((e) => {
        setButton(false);
      });
  };

  const addFile = () => {
    if (
      categoryId == 0 ||
      !nameEN ||
      !nameGJ ||
      !nameHI ||
      !file ||
      !folderName
    ) {
      setError("All field require!");
      errorTimeOut();
      return;
    }

    setButton(true);

    let formData = new FormData();
    formData.append("file", file);
    formData.append("imagePath", image);
    formData.append("categoryId", categoryId);
    formData.append("folderName", folderName);
    formData.append("nameGJ", nameGJ);
    formData.append("nameHI", nameHI);
    formData.append("nameEN", nameEN);
    formData.append("authorGJ", authorGJ);
    formData.append("authorHI", authorHI);
    formData.append("authorEN", authorEN);

    if (subCategoryId) {
      formData.append("subCategoryId", subCategoryId);
    }

    let sliderConfig = {
      method: "post",
      url: "/admin/addFile",
      headers: {
        foldername: folderName,
      },
      data: formData,
    };
    axios(sliderConfig)
      .then((response) => {
        setButton(false);
        navigate("/file");
      })
      .catch((e) => {
        setButton(false);
      });
  };

  const fileImageValidation = (file) => {
    if (!file) return;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      setBase64Image(e.target.result);
    };
    const allowFileType = ["jpeg", "jpg", "png", "pdf", "mp3", "mpeg"];
    const getFileType = file.type.split("/").pop();
    if (allowFileType.includes(getFileType)) {
      setFile(file);
    } else {
      setFile("");
      setError("File type not valid!");
      errorTimeOut();
    }
  };

  const imageValidation = (file) => {
    if (!file) return;
    // var reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = function (e) {
    //   setBase64Image(e.target.result);
    // };
    const allowFileType = ["jpeg", "jpg", "png"];
    const getFileType = file.type.split("/").pop();
    if (allowFileType.includes(getFileType)) {
      setImage(file);
    } else {
      setImage("");
      setError("File type not valid!");
      errorTimeOut();
    }
  };

  const errorTimeOut = () => {
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  return (
    <>
      <Sidebar />
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Add File</h5>

                  <form>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Category Name
                      </label>
                      <div class="col-sm-4">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={categoryId}
                          onChange={(e) => setCategoryId(e.target.value)}
                        >
                          <option selected value="">
                            Select Category
                          </option>
                          {allCategoryList.map((data, i) => (
                            <option value={data.id} key={i}>
                              {data.categoryNameGJ}, {data.categoryNameHI},{" "}
                              {data.categoryNameEN},{" "}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Sub Category Name
                      </label>
                      <div class="col-sm-4">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={subCategoryId}
                          onChange={(e) => setSubCategoryId(e.target.value)}
                        >
                          <option selected value="">
                            Select Category
                          </option>
                          {allSubCategoryList.map((data, i) => (
                            <option value={data.id} key={i}>
                              {data.subCategoryNameGJ}, {data.subCategoryNameHI}
                              , {data.subCategoryNameEN},{" "}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {!id && <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Folder Name
                      </label>
                      <div class="col-sm-4">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={folderName}
                          onChange={(e) => setFolderName(e.target.value)}
                        >
                          <option selected value="">
                            Select Folder Name
                          </option>
                          <option value="bhajan">Bhajan</option>
                          <option value="satsang">Satsang</option>
                          <option value="dharmgranth">Dharmgranth</option>
                          <option value="photo">Photo</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>}
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Name Gujarati
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Name"
                          value={nameGJ}
                          onChange={(e) => setNameGJ(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Name Hindi
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Name"
                          value={nameHI}
                          onChange={(e) => setNameHI(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Name English
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Name"
                          value={nameEN}
                          onChange={(e) => setNameEN(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Author Gujarati
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Author"
                          value={authorGJ}
                          onChange={(e) => setAuthorGJ(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Author Hindi
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Author"
                          value={authorHI}
                          onChange={(e) => setAuthorHI(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Author English
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Author"
                          value={authorEN}
                          onChange={(e) => setAuthorEN(e.target.value)}
                        />
                      </div>
                    </div>
                    {!id && (
                      <div class="row mb-3">
                        <label for="inputText" class="col-sm-3 col-form-label">
                          Image
                        </label>
                        <div class="col-sm-4">
                          <input
                            type="file"
                            class="form-control"
                            onChange={(e) => imageValidation(e.target.files[0])}
                          />
                        </div>
                        PNG, JPG, JPEG
                      </div>
                    )}
                    {!id && (
                      <div className="row mb-3">
                        <label for="inputText" class="col-sm-3 col-form-label">
                          File
                        </label>
                        <div class="col-sm-4">
                          <input
                            type="file"
                            class="form-control"
                            onChange={(e) =>
                              fileImageValidation(e.target.files[0])
                            }
                          />
                        </div>
                        PNG, JPG, JPEG, MP3, PDF
                      </div>
                    )}
                    {!id && filePath && (
                      <div class="row mb-3">
                        <label class="col-sm-3 col-form-label">
                          {filePath}
                        </label>
                        <div class="col-sm-4">
                          <a
                            href={`${url}/${filePath}`}
                            target="_blank"
                            style={{ color: "white" }}
                          >
                            <button type="button" class="btn btn-primary">
                              View
                            </button>
                          </a>
                        </div>
                      </div>
                    )}

                    {id ? (
                      <div class="row mb-3">
                        <label class="col-sm-3 col-form-label"></label>
                        <div class="col-sm-9">
                          {error && <div style={{ color: "red" }}>{error}</div>}
                          <button
                            type="button"
                            class="btn btn-primary"
                            disabled={button}
                            onClick={() => updateFile()}
                          >
                            Update File
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div class="row mb-3">
                        <label class="col-sm-3 col-form-label"></label>
                        <div class="col-sm-9">
                          {error && <div style={{ color: "red" }}>{error}</div>}
                          <button
                            type="button"
                            class="btn btn-primary"
                            disabled={button}
                            onClick={() => addFile()}
                          >
                            Add File
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AddUpdateCategory;
