import React, { useEffect, useState } from "react";
import { dateTimeFormat } from "../../common/commonFucntion";
import Sidebar from "../../Components/Sidebar";
import axios from "../../axiosConfig";
import { useNavigate, useParams } from "react-router";

const AddUpdateCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [categoryNameGJ, setCategoryNameGJ] = useState("");
  const [categoryNameHI, setCategoryNameHI] = useState("");
  const [categoryNameEN, setCategoryNameEN] = useState("");
  const [image, setImage] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const [error, setError] = useState("");
  const [button,setButton] = useState(false)

  useEffect(() => {
    if (id) {
      axios
        .post("/admin/getCategoryById", {
          id: id,
        })
        .then((response) => {
          if (response.data && response.data.data.length > 0) {
            setCategoryNameGJ(response.data.data[0].categoryNameGJ);
            setCategoryNameHI(response.data.data[0].categoryNameHI);
            setCategoryNameEN(response.data.data[0].categoryNameEN);
          }
        })
        .catch((e) => {});
    }
  }, []);

  const updateCategory = () => {
    setButton(true)
    let formData = new FormData();
    formData.append("id", id);
    formData.append("categoryNameGJ", categoryNameGJ);
    formData.append("categoryNameHI", categoryNameHI);
    formData.append("categoryNameEN", categoryNameEN);

    if (image) {
      formData.append("image", image);
    }

    let categoryUpdateConfig = {
      method: "post",
      url: "/admin/updateCategory",
      data: formData,
    };

    axios(categoryUpdateConfig)
      .then((response) => {
        setButton(false)
        navigate("/category");
      })
      .catch((e) => {
        setButton(false)
      });
  };

  const addCategory = () => {
    setButton(true)
    if (!categoryNameGJ || !categoryNameHI || !categoryNameEN || !image) {
      setError("All field require!");
      errorTimeOut();
      setButton(false)
      return;
    }

    let formData = new FormData();
    formData.append("categoryNameGJ", categoryNameGJ);
    formData.append("categoryNameHI", categoryNameHI);
    formData.append("categoryNameEN", categoryNameEN);
    formData.append("image", image);

    let categoryConfig = {
      method: "post",
      url: "/admin/addCategory",
      data: formData,
    };

    axios(categoryConfig)
      .then((response) => {
        setButton(false)
        navigate("/category");
      })
      .catch((e) => {
        setButton(false)
      });
  };

  const errorTimeOut = () => {
    setTimeout(() => {
      setError("");
    }, 2000);
  };

  const imageValidation = (file) => {
    if (!file) return;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      setBase64Image(e.target.result);
    };
    const allowFileType = ["jpeg", "jpg", "png"];
    const getFileType = file.type.split("/").pop();
    if (allowFileType.includes(getFileType)) {
      setImage(file);
    } else {
      setImage("");
      setError("Image type not valid!");
      errorTimeOut();
    }
  };

  return (
    <>
      <Sidebar />
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Add Category</h5>

                  <form>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Category Name gujarati
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Category Name"
                          value={categoryNameGJ}
                          onChange={(e) => setCategoryNameGJ(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Category Name hindi
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Category Name"
                          value={categoryNameHI}
                          onChange={(e) => setCategoryNameHI(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Category Name english
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Category Name"
                          value={categoryNameEN}
                          onChange={(e) => setCategoryNameEN(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label for="inputText" class="col-sm-3 col-form-label">
                        Image
                      </label>
                      <div class="col-sm-4">
                        <input
                          type="file"
                          class="form-control"
                          placeholder="Category Name"
                          onChange={(e) => imageValidation(e.target.files[0])}
                        />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <label
                        for="inputText"
                        class="col-sm-3 col-form-label"
                      ></label>
                      <div class="col-sm-4">
                        <div style={{ padding: "10px" }}>
                          {base64Image ? (
                            <img
                              src={base64Image}
                              height="60"
                              width="60"
                              rounded="true"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    {id ? (
                      <div class="row mb-3">
                        <label class="col-sm-3 col-form-label"></label>
                        <div class="col-sm-9">
                          {error && <div style={{ color: "red" }}>{error}</div>}
                          <button
                            type="button"
                            class="btn btn-primary"
                            disabled={button}
                            onClick={() => updateCategory()}
                          >
                            Update Category
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div class="row mb-3">
                        <label class="col-sm-3 col-form-label"></label>
                        <div class="col-sm-9">
                          {error && <div style={{ color: "red" }}>{error}</div>}
                          <button
                            type="button"
                            class="btn btn-primary"
                            disabled={button}
                            onClick={() => addCategory()}
                          >
                            Add Category
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AddUpdateCategory;
