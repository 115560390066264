import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";

const Sidebar = () => {
  return (
    <>
      <Header />
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link className="nav-link " to="/dashboard">
              <i className="bi bi-grid"></i>
              <span>Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/category">
              <i className="bi bi-grid"></i>
              <span>Category</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/subcategory">
              <i className="bi bi-grid"></i>
              <span>Sub Category</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/file">
              <i className="bi bi-grid"></i>
              <span>File</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/slider">
              <i className="bi bi-grid"></i>
              <span>Slider</span>
            </Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
