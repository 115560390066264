import React, { useEffect, useState } from "react";
import { dateTimeFormat } from "../../common/commonFucntion";
import Sidebar from "../../Components/Sidebar";
import axios from "../../axiosConfig";
import { useNavigate } from "react-router";

const Category = () => {
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [indexNumber, setIndexNumber] = useState(0);
  const [deleteId, setDeleteId] = useState(0);

  useEffect(() => {
    getAllCategory();
  }, []);

  const getAllCategory = (clickPage) => {
    axios
      .post("/admin/categoryList", {
        perPage,
        page: clickPage ? clickPage : page,
      })
      .then((response) => {
        if (response && response.data) {
          setTotalPage(Math.ceil(response.data.count / perPage));
          setCategoryData(response.data.data);
        }
      })
      .catch((e) => {});
  };

  const paginationHandler = (e, i) => {
    e.preventDefault();
    if (i != page) {
      setPage(i);
      setIndexNumber(perPage * i - perPage);
      getAllCategory(i);
    }
  };

  const deleteCategory = () => {
    if (deleteId != 0) {
      axios
        .post("/admin/deleteCategory", {
          id: deleteId,
        })
        .then((response) => {
          getAllCategory(page);
        })
        .catch((e) => {});
    }
  };

  return (
    <>
      <Sidebar />
      <main id="main" class="main">
        <section class="section">
          <div class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body">
                  <nav class="navbar navbar-light  justify-content-between">
                    <h5 class="card-title">Category</h5>
                    <h5 class="card-title align-items-center"></h5>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      onClick={() => navigate("/addcategory")}
                    >
                      Add Category
                    </button>
                  </nav>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Category Name GJ</th>
                        <th scope="col">Category Name HI</th>
                        <th scope="col">Category Name EN</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryData.length > 0 &&
                        categoryData.map((item, i) => (
                          <tr>
                            <th>{indexNumber + i + 1}</th>
                            <td>{item.categoryNameGJ}</td>
                            <td>{item.categoryNameHI}</td>
                            <td>{item.categoryNameEN}</td>
                            <td><img src={item.imagePath} height="60" width="60"/></td>
                            <td>{dateTimeFormat(item.createdAt)}</td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-danger"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                                onClick={() => setDeleteId(item.id)}
                              >
                                <i class="bi bi-trash"></i>
                              </button>
                              &nbsp;
                              <button
                                type="button"
                                class="btn btn-primary"
                                onClick={() =>
                                  navigate(`/updatecategory/${item.id}`)
                                }
                              >
                                <i class="bi bi-pencil-square"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-end">
                      <li class={`page-item ${page <= 1 ? "disabled" : ""}`}>
                        <a
                          class="page-link"
                          href="#"
                          tabIndex="-1"
                          ariaDisabled="true"
                          onClick={(e) => paginationHandler(e, page - 1)}
                        >
                          Previous
                        </a>
                      </li>
                      {totalPage &&
                        [...Array(totalPage)].map((data, i) => {
                          if (i <= page + 2 && i > page - 3) {
                            return (
                              <li
                                className={`page-item ${
                                  i + 1 == page ? "active" : ""
                                }`}
                                key={"page" + i}
                              >
                                <a
                                  class="page-link"
                                  href="#"
                                  onClick={(e) => paginationHandler(e, i + 1)}
                                >
                                  {i + 1}
                                </a>
                              </li>
                            );
                          }
                        })}
                      <li
                        class={`page-item ${
                          page >= totalPage ? "disabled" : ""
                        }`}
                      >
                        <a
                          class="page-link"
                          href="#"
                          onClick={(e) => paginationHandler(e, page + 1)}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">
                  Delete Category
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">Are your sure delete this category?</div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => deleteCategory()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Category;
